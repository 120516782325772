import { useNavigate } from '@tanstack/react-router';
import { Button, Row } from 'antd';
import { useLayoutStore } from 'features/layout/store';
import { memo } from 'react';
import { isSandbox } from 'std/url';
import sandboxIcon from './sandbox_icon.png';

function BaseButtonSandbox() {
    const navigate = useNavigate();
    const menuOpen = useLayoutStore((s) => s.menuOpen);

    if (!isSandbox()) {
        return null;
    }

    return (
        <Row justify='center'>
            <Button
                className='w-full mx-3'
                danger={true}
                icon={<img src={sandboxIcon} alt='Sandbox' className='h-6' />}
                onClick={() => navigate({ to: '/a/debug' })}
            >
                {menuOpen && 'SANDBOX'}
            </Button>
        </Row>
    );
}

export const ButtonSandbox = memo(BaseButtonSandbox);
