import { createRoot } from 'react-dom/client';
import { App } from './App/App';
import './globalStyle.scss';
import 'core-js/actual';
import { initBeforeReact } from 'initBeforeReact';
import { StrictMode } from 'react';
import '@ant-design/v5-patch-for-react-19';

initBeforeReact();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
    <StrictMode>
        <App />
    </StrictMode>,
);
