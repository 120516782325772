import { createModel } from '@rematch/core';
import { initReduxFn, setStateReducer } from 'std/redux';
import type { RootModel } from '..';
import { effects } from './effects';
import type { VendasExternasState } from './types';

const initialState: VendasExternasState = {
    get: initReduxFn,
    getOne: initReduxFn,
    post: initReduxFn,
    remove: initReduxFn,
    put: initReduxFn,
    totalizadores: initReduxFn,
    atualizarStatus: initReduxFn,
    gerarNotaRetorno: initReduxFn,

    hasUnsavedData: false,

    getTable: {
        // TOTAIS REGISTROS (PARA PAGINAÇÃO)
        totalRegistrosTable: 0,
        registroInitial: 0,
        qtdRegistros: 10,

        // ORDENAÇÃO
        sortParams: {
            shouldSort: false,
            fieldName: '',
            orderDirection: 'asc',
        },

        // PAGINAÇÃO
        pagination: {
            current: 1,
            pageSize: 10,
            total: 10,
            showTotal: () => '',
            showSizeChanger: false,
        },
    },
};

export const VendasExternasModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<VendasExternasState>(),
    effects,
});
