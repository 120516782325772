import { newRequest } from 'std/api/newRequest';

export type ApiRelatorioBoletosRecebidosParams = Partial<{
    where: string;
    orderby: string;
    /** 
        Status:
        1 - Data de pagamento
        2 - Data de vencimento
        3 - Data de emissão 
    */
    data_tipo: number;
    data_de: string;
    data_ate: string;
    financeiro_conta_idpk: number;
}>;

export type ApiRelatorioBoletos = {
    fmb_idpk: number;
    fmb_sacado_nome: string;
    fmb_sacado_cnpj_cpf: string;
    fmb_numero_documento: string;
    fmb_valor: number;
    fmb_cobranca_valor_recebido: number;
    jurosmulta: number;
    desconto: number;
    fmb_vencimento: string;
    fmb_cobranca_data_pagamento: string;
    fmb_data: string;
};

export type ApiRelatorioBoletosRecebidos = {
    fmb_idpk: number;
    fmb_sacado_nome: string;
    fmb_sacado_cnpj_cpf: string;
    fmb_numero_documento: string;
    fmb_valor: number;
    fmb_cobranca_valor_recebido: number;
    jurosmulta: number;
    desconto: number;
    fmb_vencimento: string;
    fmb_cobranca_data_pagamento: string;
    fmb_data: string;
};

export function apiRelatorioBoletosRecebidos({
    params,
}: { params: ApiRelatorioBoletosRecebidosParams }): Promise<ApiRelatorioBoletosRecebidos[]> {
    return newRequest({
        url: 'Relatorio/BoletosRecebidos',
        type: 'get',
        params,
    });
}
