import { createFileRoute } from '@tanstack/react-router'
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao'
import { validateRoute } from 'router/util'

export const Route = createFileRoute('/a/produto')({
  beforeLoad: () =>
    validateRoute({
      permissao: 'upp_cadastro_produto',
      nivel: TipoPermissao.Parcial,
      podeExibirFintech: true,
    }),
})
