import { blue, green, orange, purple } from '@ant-design/colors';
import { queryClient } from 'App/queryClient';
import { notification } from 'antd';
import type { CardProperties } from 'components/revisar/CardList';
import { useLoginStore } from 'features/login/store';
import { canUserListAllVendedores } from 'features/login/util';
import { usePdvStore } from 'features/pdv/store';
import type { Venda, VendaTipo } from 'features/venda/types/Venda';
import { getVendaDateColumn } from 'features/venda/util/util';
import type { FormVendaValues } from 'pages/vendas/forms/types';
import { isTipoVendaMasculino } from 'pages/vendas/util';
import type { RootDispatch, RootState } from 'state/store';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import { semTokenGet } from 'std/api/semToken';
import { throwIfResponseIsErr } from 'std/api/util';
import { type BuildUrlParams, buildUrl } from 'std/api2/buildUrl';
import { openPDF } from 'std/pdf';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { capitalize } from 'std/string';
import { DefaultColors, TipoConsulta } from 'std/types/enum';
import { Endpoint } from 'std/types/enum/endpoint';
import type { SortParams } from 'std/types/interfaces';
import type { RelatoriosPayload } from 'std/types/interfaces/RelatoriosPayload';
import type { TCompartilhamentoDeVenda } from './types';

const tiposVenda = {
    P: 'pedido',
    O: 'orçamento',
    V: 'venda',
    B: 'bonificação',
};

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            tipo?: string;
            pesquisar?: string;
            data_inicio?: string;
            data_fim?: string;
            transportador?: {
                nome: string;
                idpk: number;
            };
            cidade?: {
                nome: string;
                idpk: number;
            };
            total_registros?: string;
            registro_inicial?: number;
            qtde_registros?: number;
            sort?: SortParams;
            resetPagination?: boolean;
            tipo_consulta?: TipoConsulta;
            filterWhere?: string;
        },
        state: RootState,
    ): Promise<Venda[] | { error?: any }> {
        const { vendas } = state;
        const { getTable, get } = vendas;

        dispatch.vendas.setState({
            get: startReduxFn(get.data),
            getTable: {
                ...getTable,
                updateTable: false,
            },
        });

        const {
            filterTipoIntervalo,
            filterIntervalo,
            filterCliente,
            filterTipo,
            filterTransportadora,
            filterVendedor,
            filterNumero,
            filterCidade,
            filterMarcador,
            filterContrato,
            registroInitial,
            qtdRegistros,
            sortParams,
            pagination,
            resetPagination,
        } = getTable;

        const {
            tipo,
            pesquisar,
            data_inicio,
            data_fim,
            transportador,
            cidade,
            total_registros,
            registro_inicial,
            qtde_registros,
            sort,
            tipo_consulta = TipoConsulta.Resumida,
            filterWhere,
        } = payload;

        // MONTANDO OS PARAMETROS OBRIGATÓRIOS
        const params: BuildUrlParams = {
            data_tipo: filterTipoIntervalo,
            data_inicio: data_inicio || filterIntervalo?.start.format('DD-MM-YYYY'),
            data_fim: data_fim || filterIntervalo?.end.format('DD-MM-YYYY'),
            registro_inicial:
                registro_inicial !== null && registro_inicial !== undefined
                    ? registro_inicial
                    : registroInitial,
            qtde_registros: qtde_registros || qtdRegistros,
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            marcadores: filterMarcador,
            tipo_consulta,
            contrato_idpk: filterContrato?.con_idpk,
        };

        // CASO TER QUE FILTRAR (SORTEAR) OS DADOS (*OPCIONAL)
        if (sort?.shouldSort || sortParams?.shouldSort) {
            params.orderby = `${sort?.fieldName || sortParams?.fieldName}${
                sort?.orderDirection || sortParams?.orderDirection
            }`;
        } else {
            params.orderby = `${getVendaDateColumn(tipo)} desc, ven_hora desc`;
        }

        if (!canUserListAllVendedores()) {
            params.vendedor_idpk = useLoginStore.getState().login?.usu_idpk;
        } else if (filterVendedor) {
            params.vendedor_idpk = filterVendedor.idpk;
        }

        if (!!cidade || filterCidade) {
            params.cliente_cidade = cidade?.nome || filterCidade?.nome;
        }

        // CASO TIVER O FILTRO DE TIPO (*OPCIONAL)
        if (!!tipo || !!filterTipo) {
            params.tipo = tipo || filterTipo;
        }

        if (filterCliente) {
            if (filterCliente.idpk) {
                params.cliente_idpk = filterCliente.idpk;
            } else if (filterCliente.nome) {
                params.pesquisar = filterCliente?.nome;
            }
        }

        if (!filterCliente && pesquisar) {
            params.pesquisar = pesquisar;
        }

        // FILTROS QUE USAM PARAM 'WHERE'
        let where = '';
        if (!!filterTransportadora || !!transportador) {
            where = `ven_transportador_idpk=${(filterTransportadora || transportador)?.idpk}`;
        }
        if (where.length > 0) {
            params.where = where;
        }

        // CASO TIVER O FILTRO DE TIPO (*OPCIONAL)
        if (filterNumero && !filterWhere) {
            params.where = `${
                params.where ? `${params.where} AND ` : ''
            }${`ven_numero in (${filterNumero})`}`;
        }

        if (filterWhere) {
            params.where = `${params.where ? `${params.where} AND ` : ''}${filterWhere}`;
        }

        // CASO TIVER ENVIADO PARA MOSTRAR TODOS REGISTROS
        if (total_registros) {
            params.total_registros = total_registros;
        }

        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
        if (resetPagination) {
            params.registro_inicial = 0;
            params.total_registros = 'S';
        }

        const url = buildUrl(Endpoint.Venda, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [], total_registros: totalRegistroResponse = null } = {},
            } = response;

            dispatch.vendas.setState({
                get: endReduxFnOk(registros),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                    updateTable: false,
                    filterTipo: tipo || filterTipo,
                    ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                        totalRegistrosTable: totalRegistroResponse,
                    }),
                    registro_inicial:
                        registro_inicial !== null && registro_inicial !== undefined
                            ? registro_inicial
                            : registroInitial,
                    // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                    ...(resetPagination && {
                        registro_inicial: 0,
                    }),
                    qtde_registros: qtde_registros || qtdRegistros,
                    pagination: {
                        ...pagination,
                        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                        ...(resetPagination && {
                            current: 1,
                        }),
                        // SE PRECISAR ATUALIZAR A PÁGINA É FEITO AQUI
                        ...(registro_inicial !== null &&
                            registro_inicial !== undefined && {
                                current: registro_inicial / (qtde_registros || qtdRegistros) + 1,
                            }),
                        // SE PRECISAR ATUALIZAR OS TOTAIS É FEITO AQUI
                        ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                            total: totalRegistroResponse,
                            showTotal: () => `Total de Registros: ${totalRegistroResponse}`,
                            showSizeChanger: totalRegistroResponse > 10,
                        }),
                    },
                    resetPagination: false,
                },
            });

            return registros;
        } catch (error) {
            dispatch.vendas.setState({
                get: endReduxFnError(error),
            });

            return { error };
        }
    },

    async totalizador(payload: { filterWhere?: string }, state: RootState): Promise<void> {
        const { vendas } = state;
        const { totalizador, getTable } = vendas;

        dispatch.vendas.setState({
            totalizador: startReduxFn(totalizador.data),
        });

        const { filterWhere } = payload;

        const {
            filterCliente,
            filterTipoIntervalo,
            filterIntervalo,
            filterTransportadora,
            filterVendedor,
            filterNumero,
            filterCidade,
            filterMarcador,
            filterContrato,
        } = getTable;

        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            data_tipo: filterTipoIntervalo,
            data_inicio: filterIntervalo?.start.format('DD-MM-YYYY'),
            data_fim: filterIntervalo?.end.format('DD-MM-YYYY'),
            marcadores: filterMarcador,
            contrato_idpk: filterContrato?.con_idpk,
        };

        let where = '';

        if (filterCidade) {
            params.cliente_cidade = filterCidade?.nome;
        }

        if (filterTransportadora) {
            where = `ven_transportador_idpk=${filterTransportadora.idpk}`;
        }

        if (filterCliente) {
            if (filterCliente?.idpk) {
                params.cliente_idpk = filterCliente.idpk;
            } else if (filterCliente?.nome) {
                params.pesquisar = filterCliente?.nome;
            }
        }

        if (!canUserListAllVendedores()) {
            params.vendedor_idpk = useLoginStore.getState().login?.usu_idpk;
        } else if (filterVendedor) {
            params.vendedor_idpk = filterVendedor.idpk;
        }

        if (where.length > 0) {
            params.where = where;
        }

        // CASO TIVER O FILTRO DE NÚMERO (*OPCIONAL)
        if (filterNumero && !filterWhere) {
            params.where = `${
                params.where ? `${params.where} AND ` : ''
            }${`ven_numero in (${filterNumero})`}`;
        }

        if (filterWhere) {
            params.where = `${params.where ? `${params.where} AND ` : ''}${filterWhere}`;
        }

        const url = buildUrl(Endpoint.VendaValoresTotais, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            const cores = {
                bonificação: orange.primary || '',
                venda: green.primary || '',
                orçamento: purple.primary || '',
                pedido: blue.primary || '',
                cancelado: DefaultColors.Red,
                total: DefaultColors.Black,
            };

            const totais: CardProperties[] = registros.map((total) => ({
                color: cores[total.tipo],
                title: total.tipo[0].toUpperCase() + total.tipo.slice(1),
                amount: total.valor_total,
                number: total.quantidade_total,
                value: total.tipo,
            }));

            dispatch.vendas.setState({
                totalizador: endReduxFnOk(totais),
            });
        } catch (error) {
            dispatch.vendas.setState({
                totalizador: endReduxFnError(error),
            });
        }
    },

    async post(payload: {
        tipo: string;
        body: FormVendaValues;
        hideNotification?: boolean;
    }): Promise<any> {
        dispatch.vendas.setState({
            post: startReduxFn(),
        });

        const { tipo, body, hideNotification = false } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk, tipo };

        const url = buildUrl(Endpoint.Venda, params);

        const tipoCompleto = tiposVenda[tipo] as VendaTipo;
        const genero = isTipoVendaMasculino(tipoCompleto) ? 'o' : 'a';

        try {
            const response = await comTokenPost(url, body);
            throwIfResponseIsErr(response);

            const ven_idpk: number = response.data ? response.data.ven_idpk : 0;

            dispatch.vendas.setState({
                post: endReduxFnOk({ ven_idpk }),
            });

            if (!hideNotification) {
                notification.success({
                    message: 'Feito!',
                    description: `${capitalize(tipoCompleto)} cadastrad${genero}`,
                });
            }

            return response.data;
        } catch (error) {
            dispatch.vendas.setState({
                post: endReduxFnError(error),
            });

            if (!hideNotification) {
                notification.error({
                    message: `Não foi possível cadastrar este registro de ${tipoCompleto}!`,
                    description: error.message,
                });
            }

            return { error };
        }
    },

    async put(payload: {
        ven_idpk: number;
        body: FormVendaValues;
        hideNotification?: boolean;
        contratoAtualizarProximasParcelas?: string | undefined;
    }): Promise<void> {
        dispatch.vendas.setState({
            put: startReduxFn(),
        });

        const {
            ven_idpk = 0,
            body,
            hideNotification = false,
            contratoAtualizarProximasParcelas,
        } = payload;

        const params: {
            empresa_idpk: number | undefined;
            contrato_atualizar_proximas_parcelas?: string;
        } = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        if (contratoAtualizarProximasParcelas) {
            params.contrato_atualizar_proximas_parcelas = contratoAtualizarProximasParcelas;
        }

        const url = buildUrl(Endpoint.Venda, params, ven_idpk);

        const tipo = body.ven_tipo || 'venda';
        const genero = isTipoVendaMasculino(tipo) ? 'o' : 'a';

        try {
            const response = await comTokenPut(url, body);
            throwIfResponseIsErr(response);

            if (response.error) {
                notification.error({
                    message: 'Falhou!',
                    description: response.error,
                });
                return;
            }
            dispatch.vendas.setState({
                put: endReduxFnOk('Success'),
            });

            if (!hideNotification) {
                notification.success({
                    message: 'Feito!',
                    description: `${capitalize(tipo)} atualizad${genero}`,
                });
            }

            return response.data;
        } catch (e) {
            dispatch.vendas.setState({
                put: endReduxFnError(e),
            });

            if (!hideNotification) {
                notification.error({
                    message: `Não foi possível atualizar ${genero} ${tipo}!`,
                    description: e.message,
                });
            }
        }
    },

    async remove(
        payload: {
            ven_idpk: number;
            contratoExcluirProximasParcelas?: string | undefined;
            updateTable?: boolean;
        },
        state: RootState,
    ): Promise<boolean> {
        const { vendas } = state;
        const { getTable } = vendas;

        dispatch.vendas.setState({
            remove: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { ven_idpk = 0, contratoExcluirProximasParcelas, updateTable } = payload;

        const params: {
            empresa_idpk: number | undefined;
            contrato_excluir_proximas_parcelas?: string;
        } = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        if (contratoExcluirProximasParcelas) {
            params.contrato_excluir_proximas_parcelas = contratoExcluirProximasParcelas;
        }

        const url = buildUrl(Endpoint.Venda, params, ven_idpk);

        try {
            const response = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            queryClient.invalidateQueries({ queryKey: ['apiVendaGet'] });

            dispatch.vendas.setState({
                remove: endReduxFnOk('Success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                const {
                    pagination,
                    pagination: { total = 0 },
                    registroInitial = 0,
                } = getTable;

                let isLastPageOnlyOneRegister = false;

                // VERIFICA SE É A ÚLTIMA PÁGINA E TEM APENAS UM ITEM PARA PODER MUDAR DE PÁGINA APÓS DELETAR
                if (total && registroInitial && total - 1 === registroInitial) {
                    isLastPageOnlyOneRegister = true;
                    dispatch.vendas.get({
                        total_registros: 'S',
                        registro_inicial: registroInitial - (pagination?.pageSize || 0),
                    });

                    dispatch.vendas.totalizador({});
                }

                if (!isLastPageOnlyOneRegister) {
                    dispatch.vendas.get({
                        total_registros: 'S',
                    });
                    dispatch.vendas.totalizador({});
                }
            }

            usePdvStore.setState({
                venda: undefined,
            });

            notification.success({
                message: 'Feito!',
                description: 'Registro excluído com sucesso.',
            });

            return true;
        } catch (error) {
            dispatch.vendas.setState({
                remove: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });

            return false;
        }
    },

    async cancelarOrcamento(
        payload: { ven_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { vendas } = state;
        const { getTable } = vendas;

        dispatch.vendas.setState({
            cancelarOrcamento: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { ven_idpk, updateTable, ...restPayload } = payload;

        const params = {
            ...restPayload,
            empresa_idpk: useLoginStore.getState().empresaIdpk,
        };

        const url = buildUrl(Endpoint.VendaCancelar, params, ven_idpk);

        try {
            const response = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.vendas.setState({
                cancelarOrcamento: endReduxFnOk(updateTable ? null : 'success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                dispatch.vendas.get({
                    total_registros: 'S',
                });
                dispatch.vendas.totalizador({});
            }

            notification.success({
                message: 'Feito!',
                description: 'Cancelado com sucesso',
            });
        } catch (error) {
            dispatch.vendas.setState({
                cancelarOrcamento: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: (error as { message: string })?.message,
            });
        }
    },

    async gerarRelatorio(
        payload: {
            tipo: string;
            extensao: string;
            data_tipo?: string;
            data_inicio?: string;
            data_fim?: string;
            registros_list?: number[];
            gerar_fatura?: 'S';
        },
        state: RootState,
    ): Promise<void> {
        const { vendas } = state;
        const { getTable } = vendas;

        dispatch.vendas.setState({
            gerarRelatorio: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const params: BuildUrlParams = {
            ...payload,
            empresa_idpk: useLoginStore.getState().empresaIdpk,
        };

        const url = buildUrl(Endpoint.VendaRelatorio, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            openPDF({
                access: 'autenticado',
                type: 'file',
                relatorios: response.data as RelatoriosPayload,
            });

            dispatch.vendas.setState({
                gerarRelatorio: endReduxFnOk(null),
                getTable: {
                    ...getTable,
                    selectedRows: [],
                    selectedRow: undefined,
                    loadingTable: false,
                },
            });
        } catch (error) {
            dispatch.vendas.setState({
                gerarRelatorio: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        }
    },

    async gerarPDF(payload: {
        empresa_idpk: number;
        registro_idpk: number;
        isPublic?: boolean;
    }): Promise<void> {
        dispatch.vendas.setState({
            gerarPDF: startReduxFn(),
        });

        const { registro_idpk, empresa_idpk, isPublic = false } = payload;
        const params = { empresa_idpk };

        const url = buildUrl(Endpoint.VendaGerarPDF, params, registro_idpk);

        try {
            const response = await semTokenGet(url);
            throwIfResponseIsErr(response);

            const { data } = response;
            data.name = 'Venda';

            openPDF({
                access: isPublic ? 'publico' : 'autenticado',
                type: 'file',
                relatorios: response.data as RelatoriosPayload,
            });

            dispatch.vendas.setState({
                gerarPDF: endReduxFnOk(null),
            });
        } catch (error) {
            dispatch.vendas.setState({
                gerarPDF: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: (error as { message: string })?.message,
            });
        }
    },

    async anteciparVenda(
        payload: { ven_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { vendas } = state;
        const { getTable } = vendas;

        dispatch.vendas.setState({
            anteciparVenda: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { ven_idpk, updateTable } = payload;
        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            status: 1,
        };

        const url = buildUrl(Endpoint.VendaAnteciparVendaPrevista, params, ven_idpk);

        try {
            const response = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.vendas.setState({
                anteciparVenda: endReduxFnOk(updateTable ? null : 'success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                dispatch.vendas.get({});
                dispatch.vendas.totalizador({});
            }
        } catch (error) {
            dispatch.vendas.setState({
                anteciparVenda: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async lancarEstoque(
        payload: { ven_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { vendas } = state;
        const { getTable } = vendas;

        dispatch.vendas.setState({
            lancarEstoque: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { ven_idpk, updateTable } = payload;
        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            // única diferença entre esta e a fn estornar
            movimentar: 'S',
        };

        const url = buildUrl(Endpoint.VendaEstoqueManual, params, ven_idpk);

        try {
            const response = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.vendas.setState({
                lancarEstoque: endReduxFnOk(updateTable ? null : 'success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                dispatch.vendas.get({});
                dispatch.vendas.totalizador({});
            }
        } catch (error) {
            dispatch.vendas.setState({
                lancarEstoque: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async estornarEstoque(
        payload: { ven_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { vendas } = state;
        const { getTable } = vendas;

        dispatch.vendas.setState({
            estornarEstoque: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { ven_idpk, updateTable } = payload;
        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            // única diferença entre esta e a fn lançar
            estornar: 'S',
        };

        const url = buildUrl(Endpoint.VendaEstoqueManual, params, ven_idpk);

        try {
            const response = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.vendas.setState({
                estornarEstoque: endReduxFnOk(updateTable ? null : 'success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                dispatch.vendas.get({});
                dispatch.vendas.totalizador({});
            }
        } catch (error) {
            dispatch.vendas.setState({
                estornarEstoque: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async lancarFinanceiro(
        payload: { ven_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { vendas } = state;
        const { getTable } = vendas;

        dispatch.vendas.setState({
            lancarFinanceiro: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { ven_idpk, updateTable } = payload;
        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            // única diferença entre esta e a fn estornar
            movimentar: 'S',
        };

        const url = buildUrl(Endpoint.VendaFinanceiroManual, params, ven_idpk);

        try {
            const response = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.vendas.setState({
                lancarFinanceiro: endReduxFnOk(updateTable ? null : 'success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                dispatch.vendas.get({});
                dispatch.vendas.totalizador({});
            }
        } catch (error) {
            dispatch.vendas.setState({
                lancarFinanceiro: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async estornarFinanceiro(
        payload: { ven_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { vendas } = state;
        const { getTable } = vendas;

        dispatch.vendas.setState({
            estornarFinanceiro: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { ven_idpk, updateTable } = payload;
        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            // única diferença entre esta e a fn lançar
            estornar: 'S',
        };

        const url = buildUrl(Endpoint.VendaFinanceiroManual, params, ven_idpk);

        try {
            const response = await comTokenPut(url);
            throwIfResponseIsErr(response);

            dispatch.vendas.setState({
                estornarFinanceiro: endReduxFnOk(updateTable ? null : 'success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                dispatch.vendas.get({});
                dispatch.vendas.totalizador({});
            }
        } catch (error) {
            dispatch.vendas.setState({
                estornarFinanceiro: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async gerarNotaNFe(
        payload: { venda_list: number[]; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { vendas } = state;
        const { getTable } = vendas;

        dispatch.vendas.setState({
            gerarNotaNFe: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { venda_list, updateTable } = payload;

        const url = `Venda/GerarNFe?venda_list=[${venda_list}]&empresa_idpk=${
            useLoginStore.getState().empresaIdpk
        }`;

        try {
            const response = await comTokenPost(url);
            throwIfResponseIsErr(response);

            const registros = response.data?.registros || [];

            dispatch.vendas.setState({
                gerarNotaNFe: endReduxFnOk(registros[0]),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                dispatch.vendas.get({});
                dispatch.vendas.totalizador({});
            }

            notification.success({
                message: 'Feito! A nota fiscal já foi gerada!',
                description:
                    'Agora você já pode ir no menu "Nota NF-e" e transmitir para a receita.',
            });
        } catch (error) {
            dispatch.vendas.setState({
                gerarNotaNFe: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async gerarNotaNFCe(
        payload: { venda_list: number[]; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { vendas } = state;
        const { getTable } = vendas;

        dispatch.vendas.setState({
            gerarNotaNFCe: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const empresa_idpk = useLoginStore.getState().empresaIdpk;
        const { venda_list, updateTable } = payload;

        const url = `Venda/GerarNFCe?empresa_idpk=${empresa_idpk}&venda_list=[${venda_list}]`;

        try {
            const response = await comTokenPost(url);
            throwIfResponseIsErr(response);

            const registros = response.data?.registros || [];

            dispatch.vendas.setState({
                gerarNotaNFCe: endReduxFnOk(registros[0]),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                dispatch.vendas.get({});
                dispatch.vendas.totalizador({});
            }

            notification.success({
                message: 'Feito! A nota fiscal já foi gerada!',
                description:
                    'Agora você já pode ir no menu "Nota NFC-e" e transmitir para a receita.',
            });
        } catch (error) {
            dispatch.vendas.setState({
                gerarNotaNFCe: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async getCompartilhamentoDeVenda(payload: {
        id: string;
        registrarVisualizacao: boolean;
    }): Promise<void> {
        dispatch.vendas.setState({
            getCompartilhamentoDeVenda: startReduxFn(),
        });

        const { id, registrarVisualizacao } = payload;

        const params = {
            id,
            ...(registrarVisualizacao && { registrar_visualizacao: 'S' }),
        };

        const url = buildUrl(Endpoint.VendaLinkCompartilhamento, params);

        try {
            const response = await semTokenGet(url);
            throwIfResponseIsErr(response);

            dispatch.vendas.setState({
                getCompartilhamentoDeVenda: endReduxFnOk(response.data as TCompartilhamentoDeVenda),
            });
        } catch (error) {
            dispatch.vendas.setState({
                getCompartilhamentoDeVenda: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async enviarExpedicao(payload: { venIdpkList: number[] }, state: RootState): Promise<void> {
        const { getTable } = state.vendas;
        const { venIdpkList } = payload;

        dispatch.vendas.setState({
            enviarExpedicao: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        try {
            for (const ven_idpk of venIdpkList) {
                const url = buildUrl(Endpoint.VendaEnviarExpedicao, params, ven_idpk);

                try {
                    await comTokenPut(url);
                } catch {
                    //
                }
            }

            dispatch.vendas.setState({
                enviarExpedicao: endReduxFnOk(null),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            dispatch.vendas.get({});
        } catch (error) {
            // garantindo atualidade da tabela pois este put pode falhar depois de ter tido sucesso
            // com ven_idpks anteriores
            dispatch.vendas.get({});

            dispatch.vendas.setState({
                enviarExpedicao: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        }
    },
});
