import type { Models } from '@rematch/core';
import { AtendimentoModel } from './atendimento';
import { CaixaBancoModel } from './caixa-banco';
import { CobrancaBoletoModel } from './cobranca-boleto';
import { CobrancaPixCobrancaModel } from './cobranca-pix-cobranca';
import { CobrancaPixInstantaneoModel } from './cobranca-pix-instantaneo';
import { ComissaoModel } from './comissoes';
import { ComprasModel } from './compras';
import { CondicaoPagamentoModel } from './condicao-pagamento';
import { ConsultaPublicaModel } from './consulta-publica';
import { ContasPagarModel } from './contas-pagar';
import { ContasReceberModel } from './contas-receber';
import { CuponsFiscaisModel } from './cupom-fiscal';
import { FornecedoresModel } from './fornecedores';
import { ManifestoCargaModel } from './manifesto-carga';
import { MovimentoComissaoModel } from './movimento-comissao';
import { NotaFiscalModel } from './nota-fiscal';
import { NotaFiscalRegraPreenchimentoModel } from './nota-fiscal-preenchimento';
import { RelatoriosModel } from './relatorios';
import { RomaneioModel } from './romaneio';
import { TransportadorasModel } from './transportadores';
import { VendasModel } from './vendas';
import { VendasExternasModel } from './vendas-externas';

export interface RootModel extends Models<RootModel> {
    atendimento: typeof AtendimentoModel;
    caixaBanco: typeof CaixaBancoModel;
    cobrancaBoleto: typeof CobrancaBoletoModel;
    cobrancaPixCobranca: typeof CobrancaPixCobrancaModel;
    cobrancaPixInstantaneo: typeof CobrancaPixInstantaneoModel;
    compras: typeof ComprasModel;
    condicaoPagamento: typeof CondicaoPagamentoModel;
    consultaPublica: typeof ConsultaPublicaModel;
    contasReceber: typeof ContasReceberModel;
    contasPagar: typeof ContasPagarModel;
    cuponsFiscais: typeof CuponsFiscaisModel;
    fornecedores: typeof FornecedoresModel;
    manifestoCarga: typeof ManifestoCargaModel;
    movimentoComissao: typeof MovimentoComissaoModel;
    notaFiscal: typeof NotaFiscalModel;
    notaFiscalPreenchimento: typeof NotaFiscalRegraPreenchimentoModel;
    romaneio: typeof RomaneioModel;
    relatorios: typeof RelatoriosModel;
    transportadores: typeof TransportadorasModel;
    vendas: typeof VendasModel;
    vendasExternas: typeof VendasExternasModel;
    comissoes: typeof ComissaoModel;
}

export const models: RootModel = {
    atendimento: AtendimentoModel,
    caixaBanco: CaixaBancoModel,
    cobrancaBoleto: CobrancaBoletoModel,
    cobrancaPixCobranca: CobrancaPixCobrancaModel,
    cobrancaPixInstantaneo: CobrancaPixInstantaneoModel,
    compras: ComprasModel,
    condicaoPagamento: CondicaoPagamentoModel,
    consultaPublica: ConsultaPublicaModel,
    contasReceber: ContasReceberModel,
    contasPagar: ContasPagarModel,
    cuponsFiscais: CuponsFiscaisModel,
    fornecedores: FornecedoresModel,
    manifestoCarga: ManifestoCargaModel,
    movimentoComissao: MovimentoComissaoModel,
    notaFiscal: NotaFiscalModel,
    notaFiscalPreenchimento: NotaFiscalRegraPreenchimentoModel,
    romaneio: RomaneioModel,
    relatorios: RelatoriosModel,
    transportadores: TransportadorasModel,
    vendas: VendasModel,
    vendasExternas: VendasExternasModel,
    comissoes: ComissaoModel,
};
