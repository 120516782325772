import { z } from 'zod';

export const PaisSchema = z.object({
    pai_idpk: z.number(),
    pai_descricao: z.string(),
});

export type Pais = z.infer<typeof PaisSchema>;

export const CidadeSchema = z.object({
    pais: PaisSchema,

    cid_idpk: z.number(),
    cid_pais_idpk: z.number(),
    cid_cidade: z.string(),
    cid_uf: z.string(),
    cid_ibge: z.string(),
    cid_timezone: z.string(),
    cid_ultima_alteracao: z.string(),
});

export type Cidade = z.infer<typeof CidadeSchema>;
