import { createFileRoute } from '@tanstack/react-router'
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao'
import { validateRoute } from 'router/util'

export const Route = createFileRoute('/a/transportador')({
  beforeLoad: () =>
    validateRoute({
      permissao: 'upp_cadastro_transportadora',
      nivel: TipoPermissao.Parcial,
      podeExibirFintech: false,
    }),
})
