import { newRequest } from 'std/api/newRequest';

export type ApiRelatorioBoletosParams = Partial<{
    where: string;
    orderby: string;
    /** 
        Tipo de data que será considerada no período:
        1 - Aguardando
        2 - Liquidado
        3 - Liquidado Pix
        4 - Incorreto
        5 - Baixado
    */
    status: string;
    /** 
        Tipo de data que será considerada no período:
        1 - Data de pagamento
        2 - Data de vencimento
        3 - Data de emissão 
    */
    data_tipo: number;
    data_de: string;
    data_ate: string;
    financeiro_conta_idpk: number;
}>;

export type ApiRelatorioBoletos = {
    fmb_idpk: number;
    fmb_sacado_nome: string;
    fmb_sacado_cnpj_cpf: string;
    cidade: string;
    fmb_valor: number;
    fmb_vencimento: string;
    fmb_data: string;
    fmb_status: string;
    fmb_numero_documento: string;
    fmb_referencia: string;
};

export function apiRelatorioBoletos({
    params,
}: { params: ApiRelatorioBoletosParams }): Promise<ApiRelatorioBoletos[]> {
    return newRequest({
        url: 'Relatorio/Boletos',
        type: 'get',
        params,
    });
}
