import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from '@tanstack/react-router';
import { Button } from 'antd';
import type { RoutePath } from 'router/util';

export function ButtonVoltar({ to, params }: { to?: RoutePath; params?: Record<string, string> }) {
    if (to) {
        return (
            <Link to={to} params={params}>
                <Button icon={<ArrowLeftOutlined />}>Voltar</Button>
            </Link>
        );
    }

    return (
        <Button icon={<ArrowLeftOutlined />} onClick={() => window.history.back()}>
            Voltar
        </Button>
    );
}
