import { memo } from 'react';
import { useSistemaStore } from 'features/sistema/store';

export const Copyright = memo(() => {
    const sistema = useSistemaStore((s) => s.sistema);

    return (
        <div className='pt-3 pb-5 mt-auto'>
            <ul className='flex flex-wrap mb-0 justify-center'>
                <li>
                    <a
                        href={sistema?.sis_termos_de_uso}
                        target='_blank'
                        className='flex items-center p-2 ml-2 text-primary'
                        rel='noreferrer'
                    >
                        Termos de uso
                    </a>
                </li>
                <li>
                    <a
                        href={sistema?.sis_politica_de_privacidade}
                        target='_blank'
                        className='flex items-center p-2 ml-2 text-primary'
                        rel='noreferrer'
                    >
                        Política de privacidade
                    </a>
                </li>
            </ul>
            <div className='text-center'>Copyright © {sistema?.sis_copyright}</div>
        </div>
    );
});
