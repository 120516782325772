import type { ApiSistema } from 'features/sistema/types';
import { newRequest } from 'std/api/newRequest';
import { URL_ATUAL } from 'std/url';

export function apiSistemaPost(): Promise<ApiSistema[]> {
    return newRequest({
        url: 'Sistema/Sistema',
        type: 'post',
        body: { url: URL_ATUAL },
        token: false,
    });
}
