import { createFileRoute } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import dayjs from 'dayjs';
import { ErroOrigem } from 'features/erro/types';
import { DefaultSearchParamsSchema } from 'std/api/types';
import { z } from 'zod';

const ErroInternoRouteSchema = DefaultSearchParamsSchema.extend({
    visualizacao: z.enum(['card', 'table']).default('card'),
    origem: z.nativeEnum(ErroOrigem).default(ErroOrigem.Todos),
    ultima_alteracao: z
        .string()
        .default(dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss')),
    consulta: z.string().default(''),
});

export type ErroInternoRouteParams = z.infer<typeof ErroInternoRouteSchema>;

export const Route = createFileRoute('/p/erro/interno')({
    validateSearch: zodValidator(ErroInternoRouteSchema),
});
