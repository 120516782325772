import { notification } from 'antd';
import { useAutoLoginRedirectStore } from 'features/auto-login-redirect/store';
import { useLoginStore } from 'features/login/store';
import { useCobrancaPixStore } from 'pages/cobranca-pix/store';
import type { RootDispatch, RootState } from 'state/store';
import { comTokenGet, comTokenPost, comTokenRemove } from 'std/api/comToken';
import type { ApiResponse } from 'std/api/types';
import { throwIfResponseIsErr } from 'std/api/util';
import { type BuildUrlParams, buildUrl } from 'std/api2/buildUrl';
import { openPDF } from 'std/pdf';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import type { ValorTotalItem } from 'std/types';
import { DataAte, DataDe, DefaultColors } from 'std/types/enum';
import { Endpoint } from 'std/types/enum/endpoint';
import type { SortParams } from 'std/types/interfaces';
import type { RelatoriosPayload } from 'std/types/interfaces/RelatoriosPayload';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            total_registros?: string;
            registro_inicial?: number;
            qtde_registros?: number;
            sort?: SortParams;
            resetPagination?: boolean;
            fmp_idpk?: number;
        },
        state: RootState,
    ): Promise<void> {
        const { cobrancaPixCobranca } = state;
        const { getTable, get } = cobrancaPixCobranca;

        dispatch.cobrancaPixCobranca.setState({
            get: startReduxFn(get.data),
            getTable: {
                ...getTable,
                updateTable: false,
                loadingTable: true,
            },
        });

        try {
            const { filterDate, filterDescricao, filterUsuarioIDPK, filterStatus } =
                useCobrancaPixStore.getState();

            const {
                registroInitial,
                qtdRegistros,
                sortParams,
                pagination,
                resetPagination,
                dateSearchType,
            } = getTable;

            const { total_registros, registro_inicial, qtde_registros, sort, fmp_idpk } = payload;

            // MONTANDO OS PARAMETROS OBRIGATÓRIOS
            const params: BuildUrlParams = {
                [dateSearchType.de]: `${filterDate?.start.format('DD-MM-YYYY')} ${
                    dateSearchType.de !== DataDe.Vencimento ? '00:00:01' : ''
                }`,
                [dateSearchType.ate]: `${filterDate?.end.format('DD-MM-YYYY')} ${
                    dateSearchType.ate !== DataAte.Vencimento ? '23:59:59' : ''
                }`,
                registro_inicial:
                    registro_inicial !== null && registro_inicial !== undefined
                        ? registro_inicial
                        : registroInitial,
                qtde_registros: qtde_registros || qtdRegistros,
                orderby: 'fmp_data desc',
                empresa_idpk: useLoginStore.getState().empresaIdpk,
            };

            // CASO HOUVEREM FILTROS OPCIONAIS
            if (filterUsuarioIDPK) {
                params.usuario_idpk = filterUsuarioIDPK;
            }

            if (filterStatus && filterStatus !== 'Total') {
                params.status = filterStatus;
            }

            if (filterDescricao && filterDescricao.length > 0) {
                params.where = `fmp_descricao like '%${filterDescricao}%' OR fmp_sacado_nome like '%${filterDescricao}%'`;
            }

            // CASO TENHA QUE ORDENAR OS DADOS (*OPCIONAL)
            if (sort?.shouldSort || sortParams?.shouldSort) {
                params.orderby = `${sort?.fieldName || sortParams?.fieldName}${
                    sort?.orderDirection || sortParams?.orderDirection
                }`;
            }

            // CASO TIVER ENVIADO PARA MOSTRAR TODOS REGISTROS
            if (total_registros) {
                params.total_registros = total_registros;
            }

            const { redirect_idpk, redirect_options } = useAutoLoginRedirectStore.getState();

            if (redirect_idpk) {
                params.where = `fmb_idpk = ${redirect_idpk}`;
                params.data_de = undefined;
                params.data_ate = undefined;
            }

            if (redirect_options?.data_inicio) {
                params.data_de = redirect_options.data_inicio;
                params.data_ate = redirect_options.data_fim;
            }

            const usuarioEmpresa = useLoginStore.getState().getUsuarioEmpresa();

            if (usuarioEmpresa?.usuario_perfil_tipo !== 'Administrador') {
                params.usuario_idpk = usuarioEmpresa?.use_usuario_idpk;
            }
            // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
            if (resetPagination) {
                params.registro_inicial = 0;
                params.total_registros = 'S';
            }

            let url: string;
            if (typeof fmp_idpk === 'number') {
                url = buildUrl(
                    Endpoint.FinanceiroMovimentoPixCobranca,
                    { empresa_idpk: useLoginStore.getState().empresaIdpk, total_registros: 'S' },
                    fmp_idpk,
                );
            } else {
                url = buildUrl(Endpoint.FinanceiroMovimentoPixCobranca, params);
            }

            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [], total_registros: totalRegistroResponse = null } = {},
            } = response;

            dispatch.cobrancaPixCobranca.setState({
                get: endReduxFnOk(registros),
                getTable: {
                    ...getTable,
                    updateTable: false,
                    loadingTable: false,
                    ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                        totalRegistrosTable: totalRegistroResponse,
                    }),
                    // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                    ...(resetPagination && {
                        registro_inicial: 0,
                    }),
                    pagination: {
                        ...pagination,
                        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                        ...(resetPagination && {
                            current: 1,
                        }),
                        // SE PRECISAR ATUALIZAR A PÁGINA É FEITO AQUI
                        ...(registro_inicial !== null &&
                            registro_inicial !== undefined && {
                                current: registro_inicial / (qtde_registros || qtdRegistros) + 1,
                            }),
                        // SE PRECISAR ATUALIZAR OS TOTAIS É FEITO AQUI
                        ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                            total: totalRegistroResponse,
                            showTotal: () => `Total de Registros: ${totalRegistroResponse}`,
                            showSizeChanger: totalRegistroResponse > 10,
                        }),
                    },
                    resetPagination: false,
                },
            });
        } catch (error) {
            dispatch.cobrancaPixCobranca.setState({
                get: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        }
    },

    async totalizador({ fmp_idpk }: { fmp_idpk?: number }, state: RootState): Promise<void> {
        const { cobrancaPixCobranca } = state;
        const { totalizador, getTable } = cobrancaPixCobranca;

        dispatch.cobrancaPixCobranca.setState({
            totalizador: startReduxFn(totalizador.data),
        });

        const { filterDate, filterUsuarioIDPK, filterDescricao } = useCobrancaPixStore.getState();
        const { dateSearchType } = getTable;

        try {
            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                tipo_pix: 'C',
                [dateSearchType.de]: `${filterDate?.start.format('DD-MM-YYYY')} ${
                    dateSearchType.de !== DataDe.Vencimento ? '00:00:01' : ''
                }`,
                [dateSearchType.ate]: `${filterDate?.end.format('DD-MM-YYYY')} ${
                    dateSearchType.ate !== DataAte.Vencimento ? '23:59:59' : ''
                }`,
            };

            if (filterUsuarioIDPK) {
                params.usuario_idpk = filterUsuarioIDPK;
            }

            if (filterDescricao && filterDescricao.length > 0) {
                params.where = `fmp_descricao like '%${filterDescricao}%' OR fmp_sacado_nome like '%${filterDescricao}%'`;
            }

            const usuarioEmpresa = useLoginStore.getState().getUsuarioEmpresa();

            if (usuarioEmpresa?.usuario_perfil_tipo !== 'Administrador') {
                params.usuario_idpk = usuarioEmpresa?.use_usuario_idpk;
            }

            let url: string;
            if (typeof fmp_idpk === 'number') {
                url = buildUrl(
                    Endpoint.FinanceiroMovimentoPixValoresTotais,
                    { empresa_idpk: useLoginStore.getState().empresaIdpk },
                    fmp_idpk,
                );
            } else {
                url = buildUrl(Endpoint.FinanceiroMovimentoPixValoresTotais, params);
            }

            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            const cores = {
                Vencida: DefaultColors.Black,
                Aguardando: DefaultColors.Blue,
                Devolvido: DefaultColors.Red,
                Liquidado: DefaultColors.Green,
                Total: DefaultColors.Purple,
                'Liquidado Boleto': DefaultColors.DarkGreen,
            };

            const totais = registros.map((total: ValorTotalItem) => ({
                color: cores[total.status],
                title: total.status,
                amount: total.valor_total,
                number: total.quantidade_total,
                value: total.status,
            }));

            dispatch.cobrancaPixCobranca.setState({
                totalizador: endReduxFnOk(totais),
            });
        } catch (error) {
            dispatch.cobrancaPixCobranca.setState({
                totalizador: endReduxFnError(error),
            });
        }
    },

    async remove(
        payload: { idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { cobrancaPixCobranca } = state;
        const { getTable } = cobrancaPixCobranca;

        dispatch.cobrancaPixCobranca.setState({
            remove: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        try {
            const { idpk = 0, updateTable } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.FinanceiroMovimentoPix, params, idpk);
            const response: ApiResponse = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.cobrancaPixCobranca.setState({
                remove: endReduxFnOk(null),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                const {
                    pagination,
                    pagination: { total = 0 },
                    registroInitial = 0,
                } = getTable;

                let isLastPageOnlyOneRegister = false;

                // VERIFICA SE É A ÚLTIMA PÁGINA E TEM APENAS UM ITEM PARA PODER MUDAR DE PÁGINA APÓS DELETAR
                if (total && registroInitial && total - 1 === registroInitial) {
                    isLastPageOnlyOneRegister = true;
                    dispatch.cobrancaPixCobranca.get({
                        total_registros: 'S',
                        registro_inicial: registroInitial - (pagination?.pageSize || 0),
                    });

                    dispatch.cobrancaPixCobranca.totalizador({});
                }

                if (!isLastPageOnlyOneRegister) {
                    dispatch.cobrancaPixCobranca.get({
                        total_registros: 'S',
                    });

                    dispatch.cobrancaPixCobranca.totalizador({});
                }
            }

            notification.success({
                message: 'Feito!',
                description: 'Pix deletado com sucesso',
            });
        } catch (error) {
            notification.error({
                message: 'Falhou!',
                description: String(error),
            });

            dispatch.cobrancaPixCobranca.setState({
                remove: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        }
    },

    async estornar(
        payload: { pix_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { cobrancaPixCobranca } = state;
        const { getTable } = cobrancaPixCobranca;

        dispatch.cobrancaPixCobranca.setState({
            estornar: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        try {
            const { pix_idpk, updateTable } = payload;
            const params = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                pix_idpk: pix_idpk,
            };

            const url = buildUrl(Endpoint.FinanceiroMovimentoEstornarPix, params);
            const response: ApiResponse = await comTokenPost(url);
            throwIfResponseIsErr(response);

            notification.success({
                message: 'Feito!',
                description: 'Pix estornado com sucesso!',
            });

            dispatch.cobrancaPixCobranca.setState({
                estornar: endReduxFnOk(null),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                dispatch.cobrancaPixCobranca.get({});
            }
        } catch (error) {
            notification.error({
                message: 'Falhou!',
                description: String(error),
            });

            dispatch.cobrancaPixCobranca.setState({
                estornar: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        }
    },

    async gerarListaPDFs(payload: { tipoPagto: 'P' | 'C' }, state: RootState): Promise<void> {
        const { cobrancaPixCobranca } = state;
        const { getTable } = cobrancaPixCobranca;

        dispatch.cobrancaPixCobranca.setState({
            gerarListaPDFs: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { tipoPagto } = payload;
        const { selectedRows } = state.cobrancaPixCobranca.getTable;

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            registros_list: JSON.stringify(selectedRows),
            tipo: tipoPagto,
        };

        const url = buildUrl(Endpoint.FinanceiroMovimentoPixGerarListaPDFs, params);

        try {
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            if (selectedRows.length === 1) {
                openPDF({
                    access: 'autenticado',
                    type: 'link',
                    link: response.data.link_url,
                });
            } else {
                openPDF({
                    access: 'autenticado',
                    type: 'file',
                    relatorios: response.data as RelatoriosPayload,
                });
            }

            dispatch.cobrancaPixCobranca.setState({
                gerarListaPDFs: endReduxFnOk(null),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        } catch (error) {
            dispatch.cobrancaPixCobranca.setState({
                gerarListaPDFs: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        }
    },

    async consultarSituacaoPixCobranca(
        payload: { fmp_idpk?: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { cobrancaPixCobranca } = state;
        const { getTable } = cobrancaPixCobranca;

        dispatch.cobrancaPixCobranca.setState({
            consultarSituacaoPixCobranca: startReduxFn(),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { fmp_idpk = 0, updateTable = true } = payload;

        const params = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
        };

        const url = buildUrl(Endpoint.FinanceiroMovimentoPixConsultarSituacao, params, fmp_idpk);

        try {
            const response = await comTokenPost(url);
            throwIfResponseIsErr(response);

            dispatch.cobrancaPixCobranca.setState({
                consultarSituacaoPixCobranca: endReduxFnOk('Success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                dispatch.cobrancaPixCobranca.get({});
            }
        } catch (error) {
            dispatch.cobrancaPixCobranca.setState({
                consultarSituacaoPixCobranca: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });
        }
    },
});
