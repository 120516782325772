import { useNavigate, useSearch } from '@tanstack/react-router';
import type { TablePaginationConfig } from 'antd';
import type { SortOrder, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { DefaultSearchParams } from 'std/api/types';
import { omit } from 'std/util';

export type TableChangeFunction<T> = (
    newPagination: TablePaginationConfig,
    _,
    sorter: SorterResult<T> | SorterResult<T>[],
    extra: TableCurrentDataSource<T>,
) => void;

const fields: (keyof DefaultSearchParams)[] = [
    'registro_inicial',
    'qtde_registros',
    'total_registros',
];

function showTotal(total: number): string {
    return `Total de registros: ${total}`;
}

export function _updateTotalRegistros(
    data: Record<string, unknown>[] | number | undefined | null,
): number | null {
    if (typeof data === 'number') {
        return data;
    }

    if (Array.isArray(data)) {
        if (data.length === 0) {
            return 0;
        }

        if (data[0] && typeof data[0].total_registros === 'number') {
            return data[0].total_registros;
        }
    }

    return null;
}

export function usePaginationSearchParams<T extends DefaultSearchParams>() {
    const navigate = useNavigate();

    //@ts-ignore da erro aqui ao usar as T
    const searchParams = useSearch({ strict: false }) as T;
    const registro_inicial = searchParams.registro_inicial || 0;
    const qtde_registros = searchParams.qtde_registros || 10;
    const total_registros = searchParams.total_registros || 'N';

    const paginaAtual = registro_inicial / qtde_registros + 1;

    const [totalRegistros, setTotalRegistros] = useState<number | null>(null);
    const [oldSearchParams, setOldSearchParams] = useState(searchParams);
    const [params, setParams] = useState(
        totalRegistros === null ? { ...searchParams, total_registros: 'S' } : searchParams,
    );

    const searchParamMudou = useMemo(() => {
        if (!oldSearchParams) {
            return false;
        }

        const old = omit(oldSearchParams, fields);
        const current = omit(searchParams, fields);
        return JSON.stringify(old) !== JSON.stringify(current);
    }, [oldSearchParams, searchParams]);

    useEffect(() => {
        const newParams = { ...searchParams };

        if (totalRegistros === null && total_registros !== 'S') {
            navigate({
                to: '.',
                search: (prev) => ({ ...prev, total_registros: 'S' }),
            });

            return;
        }

        // Se algum search param mudou, exceto os da paginação, ir para a página 1 e puxar o total de registros novamente
        if (searchParamMudou) {
            setOldSearchParams(searchParams);
            navigate({
                to: '.',
                search: (prev) => ({ ...prev, registro_inicial: 0, total_registros: 'S' }),
            });

            return;
        }

        setOldSearchParams(searchParams);
        setParams(newParams);
    }, [searchParams, totalRegistros, navigate, searchParamMudou, total_registros]);

    const tablePagination: TablePaginationConfig = useMemo(() => {
        return {
            total: totalRegistros || 0,
            current: paginaAtual,
            pageSize: params.qtde_registros,
            showTotal,
        };
    }, [totalRegistros, paginaAtual, params.qtde_registros]);

    const onTableChange = useCallback(
        <U>(
            newPagination: TablePaginationConfig,
            _,
            sorter: SorterResult<U> | SorterResult<U>[],
            extra: TableCurrentDataSource<U>,
        ): void => {
            let order: string | undefined;

            if (extra.action === 'sort') {
                let field: string | undefined;
                let direction: SortOrder | undefined;

                if (Array.isArray(sorter)) {
                    field = sorter[0]?.field as string;
                    direction = sorter[0]?.order;
                } else {
                    field = sorter.field as string;
                    direction = sorter.order;
                }

                if (field && direction) {
                    order = `${field} ${direction === 'ascend' ? 'asc' : 'desc'}`;
                }
            }

            const search: DefaultSearchParams = {
                orderby: order,
                qtde_registros: newPagination.pageSize || 10,
                registro_inicial:
                    (newPagination.current || 1) * (newPagination.pageSize || 10) -
                    (newPagination.pageSize || 10),
                total_registros: 'N',
            };

            navigate({ to: '.', search: (prev) => ({ ...prev, ...search }) });
        },
        [navigate],
    );

    const updateTotalRegistros = useCallback(
        (data: Record<string, unknown>[] | number | undefined | null): void => {
            const total = _updateTotalRegistros(data);
            if (total !== null) {
                setTotalRegistros(total);
            }
        },
        [],
    );

    return {
        params,
        tablePagination,
        onTableChange,
        updateTotalRegistros,
    };
}
