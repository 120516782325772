import type { Dayjs } from 'dayjs';
import { getOneMonthPeriod } from 'std/datetime';
import { create } from 'zustand';

type CobrancaPixStore = {
    filterDescricao: string;
    filterDate: Interval<Dayjs>;
    filterUsuarioIDPK: number;
    filterStatus: string;
};

// store temporaria
export const useCobrancaPixStore = create<CobrancaPixStore>()((_) => ({
    filterDescricao: '',
    filterDate: getOneMonthPeriod(),
    filterUsuarioIDPK: 0,
    filterStatus: '',
}));
