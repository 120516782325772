import type { CardProperties } from 'components/revisar/CardList/CardList';
import type { ICompartilhar } from 'components/revisar/FormCompartilhar';
import type { Dayjs } from 'dayjs';
import type { Acao } from 'features/acao/types';
import type { ClienteFiscalPerfil } from 'features/cliente/types/enum';
import type { Empresa } from 'features/empresa/types/Empresa';
import type { ApiNotaFiscalEstorno } from 'features/nota-fiscal/types/ApiNotaFiscalEstorno';
import type { ApiNotaFiscalEvento } from 'features/nota-fiscal/types/ApiNotaFiscalEvento';
import type { ApiNotaFiscalFormaPagamento } from 'features/nota-fiscal/types/ApiNotaFiscalFormaPagamento';
import type { ApiNotaFiscalVenda } from 'features/nota-fiscal/types/ApiNotaFiscalVenda';
import type { ApiNotaFiscalVendaExterna } from 'features/nota-fiscal/types/ApiNotaFiscalVendaExterna';
import type { ApiNotaFiscalVolume } from 'features/nota-fiscal/types/ApiNotaFiscalVolume';
import type { TipoPessoa } from 'features/pessoa/enum';
import type { SistemaLayout } from 'features/sistema/types';
import type {
    FormNotaFiscalProduto,
    FormNotaFiscalRegrasProduto,
} from 'pages/nota-fiscal/forms/type/types';
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import type { ReduxFn } from 'std/redux';
import type { BasicSelectOption, SimNao, TablePagination, TipoDesconto } from 'std/types';
import type { FormEditMode } from 'std/types/enum';
import type { RowNotaFiscal } from 'std/types/form/RowNotaFiscal';
import type { SortParams } from 'std/types/interfaces';

export enum EStatusNotaFiscal {
    Aguardando = 1,
    Incorreta = 2,
    Emitida = 3,
    Cancelada = 4,
    Rejeitada = 5,
    Estornada = 6,
    Nova = 0, // Este status tem somente no front
}

export type TNotaFiscalItem = {
    key?: string;
    nfi_idpk?: number;
    nfi_item?: number;
    nfi_produto_idpk?: number | undefined;
    nfi_produto_nome: string;
    nfi_detalhes?: string;
    nfi_produto_unidade_idpk?: number | undefined;
    nfi_produto_unidade_sigla?: string;

    // Virtual Campos
    produtos?: number;

    // "Item valores"
    nfi_quantidade: number | null;
    nfi_valor_unitario: number;
    nfi_valor_custo?: number;
    nfi_desconto_tipo: TipoDesconto;
    nfi_desconto_valor: number;
    nfi_valor_total: number;

    // Informações do Item
    nfi_produto_codigo_interno?: number | undefined;
    nfi_produto_sku?: string;
    nfi_produto_origem_codigo?: number | undefined;
    nfi_produto_cod_barra?: string;
    nfi_produto_cest_codigo?: string | null;
    nfi_produto_cest_descricao?: string | null;
    nfi_cfop_codigo?: number | undefined;
    nfi_ncm?: string | null;
    nfi_ncm_descricao?: string | null;

    // ICMS
    nfi_icms_cst?: string;
    nfi_icms_reducao?: number;
    nfi_icms_diferido_percentual?: number;
    nfi_beneficio_fiscal_codigo?: string;
    nfi_icms_diferido_valor?: number;
    nfi_icmsop_diferido_valor?: number;
    nfi_icms_base?: number;
    nfi_icms_aliquota?: number;
    nfi_icms_valor?: number;
    nfi_icms_fcp_base?: number;
    nfi_icms_fcp_aliquota?: number;
    nfi_icms_fcp_valor?: number;
    nfi_icms_partilha_vfcpufdest?: number;
    nfi_icms_partilha_vicmsufdest?: number;

    // IPI
    nfi_ipi_cst?: string;
    nfi_ipi_base?: number;
    nfi_ipi_aliquota?: number;
    nfi_ipi_valor?: number;
    nfi_ipi_somar_bc_icms?: SimNao;
    nfi_ipi_tipo_tribucacao?: number;
    nfi_ipi_valor_unidade?: number;

    // ICMS RETIDO
    nfi_icmsstret_base?: number;
    nfi_icmsstret_aliquota?: number;
    nfi_icmsstret_valor?: number;
    nfi_icmsstret_substituto?: number;
    nfi_icmsstret_fcp_aliquota?: number;

    // ICMS ST
    nfi_icmsst_mva?: number;
    nfi_icmsst_reducao?: number;
    nfi_icmsst_base?: number;
    nfi_icmsst_aliquota?: number;
    nfi_icmsst_valor?: number;
    nfi_icmsst_fcp_valor?: number;
    nfi_icmsst_fcp_base?: number;
    nfi_icmsst_fcp_aliquota?: number;
    nfi_icmsstret_fcp_base?: number;
    nfi_icmsstret_fcp_valor?: number;

    // PIS
    nfi_pis_cst?: string;
    nfi_pis_base?: number;
    nfi_pis_aliquota?: number;
    nfi_pis_valor?: number;
    nfi_pis_tipo_tribucacao?: number;
    nfi_pis_valor_unidade?: number;
    nfi_pisst_base?: number;
    nfi_pisst_aliquota?: number;
    nfi_pisst_valor?: number;
    nfi_pisst_tipo_tribucacao?: number;
    nfi_pisst_valor_unidade?: number;

    // COFINS
    nfi_cofins_cst?: string;
    nfi_cofins_base?: number;
    nfi_cofins_aliquota?: number;
    nfi_cofins_valor?: number;
    nfi_cofins_tipo_tribucacao?: number;
    nfi_cofins_valor_unidade?: number;
    nfi_cofinsst_base?: number;
    nfi_cofinsst_aliquota?: number;
    nfi_cofinsst_valor?: number;
    nfi_cofinsst_tipo_tribucacao?: number;
    nfi_cofinsst_valor_unidade?: number;

    // IMPORTAÇÃO
    nfi_importacaoi_ii_base?: number;
    nfi_importacaoi_ii_valor?: number;
    nfi_importacaoi_ii_iof?: number;
    nfi_ii_despesas_aduaneiras?: number;

    // SIMPLES NACIONAL
    nfi_simples_csosn?: string;
    nfi_simples_credito_aliquota?: number;
    nfi_simples_credito_valor?: number;

    // OUTROS
    nfi_informacoes_adicionais?: string;
    nfi_pedido_numero?: string;
    nfi_pedido_item?: string;
    nfi_peso_bruto?: number;
    nfi_peso_liquido?: number;
    nfi_valor_seguro?: number;
    nfi_valor_outro?: number;
    nfi_valor_frete?: number;

    // Impostos
    nfi_ibpt_total_nacional?: number;
    nfi_ibpt_total_estadual?: number;
    nfi_ibpt_total_municipal?: number;

    // Virtual Campos
    isConfirm?: boolean;
    isNaoSomarICMS?: boolean;
    itensRegrasProduto?: FormNotaFiscalRegrasProduto | undefined;

    // Info de regras fiscais
    info?: {
        regra_fiscal_ipi_idpk: number;
        regra_fiscal_icms_idpk: number;
        regra_fiscal_pis_idpk: number;
        regra_fiscal_cofins_idpk: number;
        regra_fiscal_ii_idpk: number;
        regra_fiscal_informacoes_idpk: number;
    };
};

type TFormNotaFiscalNFE = {
    nfr_nfe_chave?: number;
};

type TFormNotaFiscalNFModel = {
    nfr_nf_modelo?: number;
    nfr_nf_numero?: number;
    nfr_nf_uf?: string;
    nfr_nf_cnpj?: string;
    nfr_nf_serie?: string;
    nfr_nf_mes?: string;
    nfr_nf_mes_aux?: string;
};

type TFormNotaFiscalNFRural = {
    nfr_nfp_numero?: number;
    nfr_nfp_mes?: string;
    nfr_nfp_serie?: string;
    nfr_nfp_funrural_aliquota?: number;
    nfr_nfp_uf?: string;
    nfr_nfp_cpf_cnpj?: string;
    nfr_nfp_ie?: string;
    nfr_nfp_mes_aux?: string;
};

export type TNotaFiscal = {
    nof_idpk?: number;
    nof_nfe_pdf?: string;
    nof_nfe_xml?: string;
    nof_link_compartilhamento?: string;
    nof_financeiro_categoria_idpk?: number;
    nof_chave?: string;
    nof_protocolo?: string;
    nof_qrcode_link?: string;

    nof_ibpt_chave?: string;
    nof_ibpt_fonte?: string;

    /** Eventos */
    eventos?: ApiNotaFiscalEvento[];

    /** VALORES FIXOS */
    nof_modelo: number;
    nof_ambiente: number | undefined;

    /* ERROR */
    nof_erro?: string;

    /* DATA PROTOCOLO */
    nof_nfe_protocolo_data_hora?: string;

    /** DADOS GERAIS */
    nof_tipo: number;
    nof_indicador_presenca: number;
    nof_indicador_presenca_forma?: string;
    nof_natureza_operacao?: string;

    // tipoNotaFiscal === 'DEVOLUÇÃO'
    nof_movimento?: number;

    // tipoNotaFiscal === 'DEVOLUÇÃO' || 'COMPLEMENTAR'
    nof_devolucao_operacao?: number;
    // nof_chave_nfe_origem?: string

    nof_motivo_venda?: number;

    // tipoNotaFiscal === 'COMPRA'
    nof_motivo_compra?: number;

    // VENRDA EXTERNA
    nof_venda_externa_idpk?: number;

    // DEVOLUÇÃO OPERAÇÃO === SE FOR POR TERCEIROS
    nof_marketplace_indicador?: number;
    nof_marketplace_cnpj?: string;
    nof_marketplace_nome?: string;
    nof_marketplace_identificacao?: string;

    // MOTIVO DA COMPRA === 'RURAL'
    nof_produtor_funrural?: string;
    nof_produtor_serie_nf?: string;
    nof_produtor_numero_nf?: string;
    nof_produtor_data_emissao?: string;
    nof_produtor_funrural_valor?: string;

    // tipoNotaFiscal === 'REMESSA' || 'ESTORNO'
    nof_remessa_retorno_motivo?: number;
    nof_remessa_retorno_operacao?: number;

    // "NotaFiscal Ações"
    acoes: Acao[] | undefined;

    /** PAGAMENTOS */
    formas_de_pagamento?: ApiNotaFiscalFormaPagamento[];

    /** PRODUTOS */
    itens?: TNotaFiscalItem[];

    /** SÉRIE/DATA */
    nof_serie: number;
    nof_data_emissao: string;
    nof_hora_emissao: string;
    nof_numero?: number;
    nof_data_saida: string;
    nof_hora_saida: string;
    nof_status?: EStatusNotaFiscal;
    /** FIM SÉRIE/DATA */

    /** DESTINATÁRIO/REMETENTE */
    nof_uf?: string;
    nof_cliente_idpk?: number;
    nof_fornecedor_idpk?: number;
    nof_dest_tipo_pessoa: TipoPessoa;
    nof_dest_cnpj_cpf: string;
    nof_dest_doc_estrangeiro?: string;
    nof_dest_razao_social?: string;
    nof_dest_nome_fantasia?: string;
    nof_dest_responsavel?: string;
    nof_dest_indicadorie?: number;
    nof_dest_ie_rg?: string;
    nof_dest_telefone?: string;
    nof_dest_email?: string;
    nof_dest_consumidorfinal?: 0 | 1;
    nof_dest_crt?: number;
    nof_dest_email_copia?: string;
    nof_dest_isuf?: string;
    nof_dest_perfil: ClienteFiscalPerfil;

    /** DESTINATÁRIO/REMETENTE OCULTOS */
    nof_dest_cep?: string;
    nof_dest_uf?: string;
    nof_dest_cidade?: string;
    nof_dest_cidade_idpk?: number;
    nof_dest_cidade_ibge?: string;
    nof_dest_bairro?: string;
    nof_dest_logradouro?: string;
    nof_dest_numero?: string;
    nof_dest_complemento?: string;

    /** DESTINATÁRIO/REMETENTE ENTREGA */
    nof_dest_ent_tipo_pessoa?: TipoPessoa;
    nof_dest_ent_nome?: string;
    nof_dest_ent_cnpj_cpf?: string;
    nof_dest_ent_ie_rg?: string;
    nof_dest_ent_email?: string;
    nof_dest_ent_telefone?: string;
    nof_dest_ent_cep?: string;
    nof_dest_ent_uf?: string;
    nof_dest_ent_cidade_idpk?: number;
    nof_dest_ent_cidade?: string;
    nof_dest_ent_bairro?: string;
    nof_dest_ent_logradouro?: string;
    nof_dest_ent_numero: string;
    nof_dest_ent_complemento?: string;

    /** DESTINATÁRIO/REMETENTE RETIRADA */
    nof_dest_ret_tipo_pessoa?: TipoPessoa;
    nof_dest_ret_nome?: string;
    nof_dest_ret_cnpj_cpf?: string;
    nof_dest_ret_ie_rg?: string;
    nof_dest_ret_email?: string;
    nof_dest_ret_telefone?: string;
    nof_dest_ret_cep?: string;
    nof_dest_ret_uf?: string;
    nof_dest_ret_cidade_idpk?: number;
    nof_dest_ret_cidade?: string;
    nof_dest_ret_bairro?: string;
    nof_dest_ret_logradouro?: string;
    nof_dest_ret_numero?: string;
    nof_dest_ret_complemento?: string;
    /** FIM DESTINATÁRIO/REMETENTE */

    /** TRANSPORTE */
    nof_transp_responsavel_frete?: number;
    nof_transp_usa_transportadora?: number;
    nfv_quantidade?: number;

    volume?: ApiNotaFiscalVolume;

    nof_transp_somar_total?: number;
    nof_total_frete: number;

    /** DADOS DA TRANSPORTADORA */
    nof_transportador_idpk?: number;
    nof_transp_nome?: string;
    nof_transp_cnpj_cpf?: string;
    nof_transp_ie?: string;
    nof_transp_endereco?: string;
    nof_transp_municipio?: string;
    nof_transp_uf?: string;
    nof_transp_veiculo_rntrc?: string;
    nof_transp_veiculo_placa?: string;
    nof_transp_veiculo_uf?: string;

    /** INFORMAÇÕES ADICIONAIS */
    nof_total_seguro?: number;
    nof_total_despesas_acessorias?: number;
    nof_total_desconto?: number;
    nof_adic_inf_complementares?: string;
    nof_adic_inf_complementares_automaticamente?: string;

    /** TOTAIS E IMPOSTOS */
    nof_total_nota?: number;
    nof_total_tipo?: TipoDesconto;
    nof_total_produtos?: number;
    nof_total_imp_ii_valor?: number;
    nof_total_imp_icms_base?: number;
    nof_total_imp_ipi_valor?: number;
    nof_total_imp_pis_valor?: number;
    nof_total_imp_icms_valor?: number;
    nof_total_imp_fcp_valor?: number;
    nof_total_imp_fcpstret_valor?: number;
    nof_total_imp_fcpufdest?: number;
    nof_total_imp_fcpst_valor?: number;
    nof_total_imp_icmsst_base?: number;
    nof_total_imp_cofins_valor?: number;
    nof_total_imp_icmsst_valor?: number;
    nof_total_imp_icmsdeson_valor?: number;
    nof_total_imp_icmsufdest?: number;
    nof_ibpt_total_nacional?: number;
    nof_ibpt_total_estadual?: number;
    nof_ibpt_total_municipal?: number;

    referenciadas: Array<
        TFormNotaFiscalNFE &
            TFormNotaFiscalNFModel &
            TFormNotaFiscalNFRural & { nfr_idpk?: number; nfr_tipo_nota: number }
    >;

    venda_externa?: ApiNotaFiscalVendaExterna;
    venda_nota_fiscal?: ApiNotaFiscalVenda[];
    estorno?: ApiNotaFiscalEstorno;

    nof_nfe_chave?: string;

    nof_marcadores?: string;

    // vendedor
    nof_vendedor_idpk: number;
    nof_vendedor_nome?: string;

    nof_origem?: number;
    nof_origem_id?: number;
};

export type TCalcularImpostosApiItens = {
    nfi_regra_fiscal_idpk: number;
    nfi_valor_unitario: number;
    nfi_quantidade: number;
    nfi_ncm: string;
    nfi_beneficio_fiscal_codigo: string;
    info?: {
        regra_fiscal_ipi_idpk: number;
        regra_fiscal_icms_idpk: number;
        regra_fiscal_pis_idpk: number;
        regra_fiscal_cofins_idpk: number;
        regra_fiscal_ii_idpk: number;
        regra_fiscal_informacoes_idpk: number;
    };
    nfi_ipi_cst: string;
    nfi_ipi_cpf_cnpj_produtor: string;
    nfi_ipi_extipi: string;
    nfi_ipi_somar_bc_icms: SimNao;
    nfi_ipi_base: number;
    nfi_ipi_aliquota: number;
    nfi_ipi_valor: number;
    nfi_icmsefetivo_base: number;
    nfi_icmsefetivo_aliquota: number;
    nfi_icmsefetivo_valor: number;
    nfi_icmsefetivo_reducao: number;
    nfi_icmsstret_aliquota: number;
    nfi_icmsstret_base: number;
    nfi_icmsstret_valor: number;
    nfi_icmsstret_substituto: number;
    nfi_icmsstret_fcp_base: number;
    nfi_icmsstret_fcp_aliquota: number;
    nfi_icmsstret_fcp_valor: number;
    nfi_cfop_codigo: string;
    nfi_simples_csosn: string;
    nfi_icms_cst: string;
    nfi_icms_aliquota: number;
    nfi_icms_modalidade: number;
    nfi_icms_diferido_percentual: number;
    nfi_icms_diferido_valor: number;
    nfi_icmsop_diferido_valor: number;
    nfi_icms_reducao: number;
    nfi_icms_base: number;
    nfi_icms_valor: number;
    nfi_icmsst_reducao: number;
    nfi_icmsst_base: number;
    nfi_icmsst_valor: number;
    nfi_icmsst_mva: number;
    nfi_pis_cst: string;
    nfi_pis_base: number;
    nfi_pis_aliquota: number;
    nfi_pis_valor: number;
    nfi_pisst_base: number;
    nfi_pisst_aliquota: number;
    nfi_pisst_valor: number;
    nfi_cofins_cst: string;
    nfi_cofins_base: number;
    nfi_cofins_aliquota: number;
    nfi_cofins_valor: number;
    nfi_cofinsst_base: number;
    nfi_cofinsst_aliquota: number;
    nfi_cofinsst_valor: number;
    nfi_importacaoi_ii_base: number;
    nfi_importacaoi_ii_valor: number;
    nfi_ii_iof: number;
    nfi_ii_despesas_aduaneiras: number;
    nfi_informacoes_adicionais: string;
    nfi_ibpt_total_nacional: number;
    nfi_ibpt_total_estadual: number;
    nfi_ibpt_total_municipal: number;
};

export type TCalcularImpostosApi = {
    itens: TCalcularImpostosApiItens[];
    nof_dest_consumidorfinal: 0 | 1;
    nof_dest_crt: number;
    nof_dest_indicadorie: number;
    nof_dest_pais_codigo: number;
    nof_dest_uf: string;
    nof_ibpt_chave: string;
    nof_ibpt_fonte: string;
    nof_motivo_venda?: number;
    nof_motivo_compra?: number;
    nof_movimento: number;
    nof_tipo: number;
    nof_transp_somar_total: number;
};

export type TCalcImpostosItens = {
    nfi_produto_idpk: number;
    nfi_regra_fiscal_idpk: number;
    nfi_valor_unitario: number;
    nfi_quantidade: number;
    nfi_ncm: string;
    nfi_beneficio_fiscal_codigo: string;
    nfi_desconto_tipo: string;
    nfi_desconto_valor: number;
    nfi_valor_outro: number;
    nfi_valor_frete: number;
    nfi_valor_seguro: number;
    produto?: {
        pro_icmsstret_base?: number;
        pro_icmsstret_aliquota?: number;
        pro_icmsstret_valor?: number;
        pro_icmsstret_substituto?: number;
        pro_icmsstret_fcp_aliquota?: number;
    };
};

export type TCalcImpostos = {
    nof_tipo: number;
    nof_motivo_venda?: number;
    nof_motivo_compra?: number;
    nof_remessa_retorno_motivo?: number;
    nof_movimento?: number;
    nof_dest_uf?: string;
    nof_dest_pais_codigo?: number;
    nof_dest_consumidorfinal?: 0 | 1;
    nof_dest_indicadorie?: number;
    nof_dest_crt?: number;
    nof_dest_perfil: ClienteFiscalPerfil;
    nof_transp_somar_total?: number;
    itens: TCalcImpostosItens[];
};

type TCompartilhamentoDeNfeParcela = {
    parcela: number;
    vencimento: string;
    valor: number;
    situacao: string;
    link: string;
};

type TCompartilhamentoDeNfeFormaPgto = {
    forma_pagamento_idpk: number;
    forma_pagamento_descricao: string;
    forma_pagamento_parcelas: number;
    forma_pagamento_valor: number;
    parcelas: TCompartilhamentoDeNfeParcela[];
};

export type TCompartilhamentoDeNotaFiscal = {
    data: string;
    empresa: Empresa;
    registro_idpk: number;
    empresa_idpk: number;
    forma_pagamento?: TCompartilhamentoDeNfeFormaPgto[] | undefined;
    tipo: string;
    numero: number;
    serie: number;
    valor: number;
    nfe_status: string;
    nfe_tipo: string;
    pessoa_nome: string;
    obj?: {
        ven_idpk: number;
        ven_tipo: 'pedido' | 'venda' | 'orçamento' | 'bonificação';
        ven_numero: number;
    };
    nota_fiscal: {
        carta_correcao: string;
        cancelamento: string;
        estorno: ApiNotaFiscalEstorno;
        nof_idpk: number;
        nof_serie: number;
        nof_numero: number;
        nof_status: number;
        nof_link_compartilhamento: string;
        nof_nfe_chave: string;
        nof_nfe_xml: string;
        nof_nfe_pdf: string;
        nof_nfe_idpk: number;
    }[];

    sistema: SistemaLayout;
    status: string;
};

export type TEstornoResult = {
    mensagem: string;
    nof_estorno_nota_fiscal_idpk: number;
    nof_idpk: number;
    status: string;
};

export type TValorTotaisNf = {
    tipo: string;
    valor_total: number;
    quantidade_total: number;
};

export type TGetTable = {
    // FILTROS
    filterDateRange?: Interval<Dayjs>;
    filterPesquisar?: string;
    filterTipo?: BasicSelectOption;
    filterCliente?: BasicSelectOption;
    filterFornecedor?: BasicSelectOption;
    filterVendedor?: BasicSelectOption;
    filterStatus?: string[];
    filterSerie?: number;
    filterNumero?: number;
    filterCidade?: BasicSelectOption;
    filterMarcador?: string;
    filterVendaExterna?: BasicSelectOption;

    // TOTAIS REGISTROS (PARA PAGINAÇÃO)
    totalRegistrosTable: number;
    registroInitial: number;
    qtdRegistros: number;
    resetPagination?: boolean;

    // ORDENAÇÃO
    sortParams: SortParams;

    // PAGINAÇÃO
    pagination: TablePagination;

    // LINHA SELECIONADAS
    selectedRows: RowNotaFiscal[];
    selectedRow: RowNotaFiscal | undefined;

    // UPDATE TABLE ON CHANGES
    updateTable: boolean;

    // LOADING NA TABELA PARA MOSTRAR ALGUMA AÇÃO EXECUTANDO
    loadingTable: boolean;
};

export type NotaFiscalState = {
    // EFFECTS
    get: ReduxFn<TNotaFiscal[]>;
    getOne: ReduxFn<TNotaFiscal>;
    post: ReduxFn<{ nof_idpk: number }>;
    put: ReduxFn<string>;
    remove: ReduxFn<string>;
    totalizador: ReduxFn<CardProperties[]>;
    cancelarNotaFiscal: ReduxFn<undefined>;
    preVisualizarDanfe: ReduxFn<undefined>;
    gerarRelatorio: ReduxFn<undefined>;
    gerarRelatorioMultiple: ReduxFn<undefined>;
    emitirNotaFiscal: ReduxFn<
        | 'ok'
        | Array<{
              nof_idpk: number;
              nof_numero: number;
              motivoErro: string;
          }>
    > & { cancel: boolean };
    compartilharSend: ReduxFn<undefined>;
    gerarCartaCorrecao: ReduxFn<undefined>;
    sendCartaCorrecao: ReduxFn<ApiNotaFiscalEvento>;
    calcularImpostos: ReduxFn<TCalcularImpostosApi>;
    getReceitaFiscal: ReduxFn<string>;
    lancarEstoque: ReduxFn<undefined>;
    estornarEstoque: ReduxFn<undefined>;
    lancarFinanceiro: ReduxFn<undefined>;
    estornarFinanceiro: ReduxFn<undefined>;
    getCompartilhamentoDeNotaFiscal: ReduxFn<TCompartilhamentoDeNotaFiscal>;
    gerarEstorno: ReduxFn<TEstornoResult>;
    enviarExpedicao: ReduxFn<undefined>;
    verifyAndGerarRelatorioPdf: ReduxFn<undefined>;

    // TABELAS
    getTable: TGetTable;

    // MOSTRAR DRAWERS
    showDrawerGetDetalhes: boolean;
    showDrawerCompartilhar: boolean;
    showDrawerEmitirNf: boolean;
    showDrawerCancelarNf: boolean;
    showDrawerEstornoNf: boolean;
    showDrawerProdutos: boolean;
    showDrawerDeclaracao: boolean;

    // VALORES PARA DRAWERS
    drawerProdutoItem?: FormNotaFiscalProduto;
    drawerProdutoIndex?: number;
    dadosCompartilhar: ICompartilhar;

    drawerProdutoLoadingIndex?: number;

    // FORM
    formEditMode?: FormEditMode;
    formError?: ValidateErrorEntity;
    triggerFormReload?: string;

    // ref notas de vendas externas (pronta entrega)
    isNotaProntaEntrega?: boolean;
    notaProntaEntregaTipo?: 'venda' | 'bonificação' | 'devolução';

    //Aba Tabs
    abaTabs?: string;
};
