import { useEffect, useRef, useState } from 'react';
import { DEBOUNCE_TIME } from 'std/const/debounceTime';

// https://github.com/mantinedev/mantine/blob/master/packages/%40mantine/hooks/src/use-debounced-value/use-debounced-value.ts

export function useDebouncedValue<T = any>(
    value: T,
    wait: number = DEBOUNCE_TIME,
    options = { leading: false },
) {
    const [_value, setValue] = useState(value);
    const mountedRef = useRef(false);
    const timeoutRef = useRef<number | null>(null);
    const cooldownRef = useRef(false);

    const cancel = (): void => {
        if (typeof timeoutRef.current === 'number') {
            window.clearTimeout(timeoutRef.current);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
        if (mountedRef.current) {
            if (!cooldownRef.current && options.leading) {
                cooldownRef.current = true;
                setValue(value);
            } else {
                cancel();
                timeoutRef.current = window.setTimeout(() => {
                    cooldownRef.current = false;
                    setValue(value);
                }, wait);
            }
        }
    }, [value, options.leading, wait]);

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
        mountedRef.current = true;
        return cancel;
    }, []);

    return [_value, cancel] as const;
}
