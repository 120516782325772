import { Link } from '@tanstack/react-router';
import { Grid } from 'antd';
import { useEmpresaQuery } from 'features/empresa/hooks/useEmpresaQuery';
import { useSistemaStore } from 'features/sistema/store';

export function Footer() {
    const screens = Grid.useBreakpoint();
    const sistema = useSistemaStore((s) => s.sistema);

    const { empresa } = useEmpresaQuery();
    const empresaSlug = empresa?.empresa_slug;
    const showCatalogo = empresaSlug?.ems_catalogo_ativo === 'S';

    return (
        <div
            style={{
                margin: '0 auto',
                padding: screens.md ? '0px 1.33rem 1.33rem' : '0px 1.06rem',
                display: 'flex',
            }}
        >
            <div style={{ borderTop: '1px solid #e4e9f0' }}>
                <a
                    href={sistema?.sis_landing_page}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-lg font-extrabold text-black uppercase'
                >
                    {sistema?.sis_nome_sistema}
                </a>

                <span style={{ color: 'rgb(89, 92, 151)', paddingLeft: 8 }}>
                    {`${APP_VERSION} - ${CURRENT_COMMIT}`}
                </span>

                <br />

                <p style={{ marginBottom: 0 }}>
                    <span className='text-gray-500 '>Copyright © {sistema?.sis_copyright}</span>

                    {showCatalogo && (
                        <Link
                            to='/p/catalogo/$slug/listagem'
                            params={{ slug: empresaSlug?.ems_slug || '' }}
                            style={{ marginLeft: 20 }}
                        >
                            Ver Catálogo
                        </Link>
                    )}
                </p>
            </div>
        </div>
    );
}
