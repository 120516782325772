import { z } from 'zod';

export const ClienteRamoAtividadeSchema = z.object({
    cra_idpk: z.number(),
    cra_empresa_idpk: z.number(),
    cra_descricao: z.string(),
    cra_ultima_alteracao: z.string(),
});

export type ClienteRamoAtividade = z.infer<typeof ClienteRamoAtividadeSchema>;

export type ClienteRamoAtividadeBody = {
    cra_descricao: string;
};
