export const URL_ATUAL = isLocalHost()
    ? //'sandbox.resultipay.com.br'
      //'sandbox.app.pixapay.com.br'
      'sandbox.app.mercurioapp.com.br'
    : window.location.hostname;

export function isSandbox(): boolean {
    const url = window.location.href;
    return url.indexOf('sandbox') > 0 || url.indexOf('localhost') > 0 || url.indexOf('192.168') > 0;
}

export function isLocalHost(): boolean {
    return window.location.href.includes('localhost');
}
