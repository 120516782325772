export enum TipoPessoa {
    Fisica = 'F',
    Juridica = 'J',
    Estrangeiro = 'E',
    NaoEspecificado = '',
}

export enum PessoaCrt {
    SimplesNacional = 1,
    SimplesNacionalExcessoReceita = 2,
    RegimeNormal = 3,
    Mei = 4,
    Outros = 5,
}

export enum PessoaSexo {
    Masculino = 'M',
    Feminino = 'F',
    Outro = 'O',
    NaoEspecificado = '',
}
