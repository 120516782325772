import { LoadingOutlined } from '@ant-design/icons';
import { Spin, type SpinProps } from 'antd';
import { memo } from 'react';

export const CustomSpin = memo((props: SpinProps) => {
    return (
        <Spin
            {...props}
            size='large'
            style={{ display: 'inline-block' }}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin={true} />}
        />
    );
});
