import { FastColor } from '@ant-design/fast-color';
import { getTextColor } from 'std/color';
import { removerAcento } from 'std/string/removerAcento';
import type { SistemaLayout } from './types';

export function generatePrimaryColorPalette(corPrimaria: string): void {
    const root = document.documentElement;
    root.style.setProperty('--kit-color-primary', corPrimaria);

    const tonalidades: Record<number, number> = {
        50: 50,
        100: 40,
        200: 30,
        300: 20,
        400: 10,
        600: 10,
        700: 20,
        800: 30,
        900: 40,
    };

    for (const [key, value] of Object.entries(tonalidades)) {
        const color = new FastColor(corPrimaria);
        let hex: string;

        if (Number(key) < 500) {
            hex = color.lighten(value).toString();
        } else {
            hex = color.darken(value).toString();
        }

        root.style.setProperty(`--kit-color-primary-${key}`, hex);
    }

    const constrastColor = getTextColor(corPrimaria);
    root.style.setProperty('--kit-color-primary-contrast', constrastColor);
}

export function setColorFromLayout(layout: SistemaLayout): void {
    const root = document.documentElement;
    root.style.setProperty('--sic_botao', layout.sic_botao);
    root.style.setProperty('--sic_botao_fundo', layout.sic_botao_fundo);
    root.style.setProperty('--sic_botao_fundo_info', layout.sic_botao_fundo_info);
    root.style.setProperty('--sic_botao_fundo_sucesso', layout.sic_botao_fundo_sucesso);
    root.style.setProperty('--sic_botao_texto', layout.sic_botao_texto);
    root.style.setProperty('--sic_card_fundo', layout.sic_card_fundo);
    root.style.setProperty('--sic_card_texto', layout.sic_card_texto);
    root.style.setProperty('--sic_cor_secundaria', layout.sic_cor_secundaria);
    root.style.setProperty('--sic_cor_sistema_nome', layout.sic_cor_sistema_nome);
    root.style.setProperty('--sic_cor_terciaria', layout.sic_cor_terciaria);
    root.style.setProperty('--sic_menu', layout.sic_menu);
    root.style.setProperty('--sic_menu_titulo', layout.sic_menu_titulo);
    root.style.setProperty('--sic_sistema_fundo', layout.sic_sistema_fundo);
    root.style.setProperty('--sic_tema', layout.sic_tema);

    if (layout.sic_cor_primaria) {
        generatePrimaryColorPalette(layout.sic_cor_primaria);
    }
}

export function changeManifestImgs(nomeSistema: string): void {
    const lowerNameSystem = removerAcento(nomeSistema).toLowerCase();

    document
        .querySelector('link[rel=manifest]')
        ?.setAttribute('href', `./${lowerNameSystem}/manifest.json`);
    document.getElementById('favicon')?.setAttribute('href', `./${lowerNameSystem}/favicon.ico`);
    document
        .getElementById('favicon2')
        ?.setAttribute('href', `./${lowerNameSystem}/apple-touch-icon.png`);
    document
        .getElementById('favicon3')
        ?.setAttribute('href', `./${lowerNameSystem}/favicon-32x32.png`);
    document
        .getElementById('favicon4')
        ?.setAttribute('href', `./${lowerNameSystem}/favicon-16x16.png`);
    document
        .getElementById('favicon5')
        ?.setAttribute('href', `./${lowerNameSystem}/safari-pinned-tab.svg`);
}
