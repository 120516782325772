import { createFileRoute, redirect } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { CidadeSchema } from 'features/cidade-uf-pais/types';
import { ClienteRamoAtividadeSchema } from 'features/cliente-ramo-atividade/types';
import { useClienteStore } from 'features/cliente/store';
import { DefaultSearchParamsSchema } from 'std/api/types';
import { isObjectEmpty } from 'std/util';
import { z } from 'zod';

// O schema do filtro precisa estar aqui na rota, se estiver na store
// vai dar erro "can't access lexical declaration 'ClienteFilterSchema' before initialization"

export const ClienteFilterSchema = DefaultSearchParamsSchema.extend({
    pesquisar: z.string().optional(),
    uf: z.string().optional(),
    cidade: CidadeSchema.pick({ cid_idpk: true, cid_cidade: true }).optional(),
    ramo_atividade: ClienteRamoAtividadeSchema.pick({
        cra_idpk: true,
        cra_descricao: true,
    }).optional(),
    marcadores: z.string().optional(),
});

export type ClienteFilter = z.infer<typeof ClienteFilterSchema>;

export const Route = createFileRoute('/a/cliente/')({
    validateSearch: zodValidator(ClienteFilterSchema),
    beforeLoad: (ctx) => {
        if (isObjectEmpty(ctx.search)) {
            const { filter } = useClienteStore.getState();
            throw redirect({ to: '/a/cliente', search: filter });
        }
    },
});
