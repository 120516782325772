import { createFileRoute, redirect } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import { useInventarioStore } from 'features/inventario/store';
import { DefaultSearchParamsSchema } from 'std/api/types';
import { isObjectEmpty } from 'std/util';
import { z } from 'zod';

export const InventarioFilterSchema = DefaultSearchParamsSchema.extend({
    mes_movimento: z.string().optional(),
});

export type InventarioFilter = z.infer<typeof InventarioFilterSchema>;

export const Route = createFileRoute('/a/estoque/inventario/')({
    validateSearch: zodValidator(InventarioFilterSchema),
    beforeLoad: (ctx) => {
        if (isObjectEmpty(ctx.search)) {
            const { filter } = useInventarioStore.getState();
            throw redirect({ to: '/a/estoque/inventario', search: filter });
        }
    },
});
