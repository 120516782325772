import { apiInternoErro } from 'features/erro/api/apiInternoErroPost';
import { type AnyZodObject, z } from 'zod';

/** Aplica "nullable" to all properties in zod */
export function applyNullableZodObject<Schema extends z.AnyZodObject>(schema: Schema) {
    const entries = Object.entries(schema.shape) as [keyof Schema['shape'], z.ZodTypeAny][];

    const newProps = entries.reduce(
        (acc, [key, value]) => {
            acc[key] =
                value instanceof z.ZodOptional ? value.unwrap().nullable() : value.nullable();
            return acc;
        },
        {} as {
            [key in keyof Schema['shape']]: Schema['shape'][key] extends z.ZodOptional<infer T>
                ? z.ZodNullable<T>
                : z.ZodNullable<Schema['shape'][key]>;
        },
    );

    return z.object(newProps);
}

export function zodParse<Schema extends z.AnyZodObject>(
    schema: Schema,
    data: unknown,
): z.infer<Schema> {
    const result = schema.safeParse(data);

    if (result.success) {
        return result.data;
    }

    // biome-ignore lint/suspicious/noConsole:
    console.error(result.error);

    apiInternoErro({
        type: 'ZOD_PARSE_ERROR',
        message: JSON.stringify(result.error),
    });

    throw result.error;
}

export function createDefaultSchema<T extends AnyZodObject>(obj: T) {
    return z.object({
        registros: z.array(obj),
        total_registros: z.number().optional(),
        status: z.literal('sucesso'),
    });
}
